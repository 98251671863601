@import url("fonts.css");

body {
  font-family: "Epilogue", sans-serif;
  background-color: #000;
}

.app-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-color: #000;
}

.bg-pattern-2nd-layer {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
}

@keyframes pulseAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
